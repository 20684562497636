import React from "react"
import Layout from "../components/Layout"
import PageTransition from "gatsby-plugin-page-transitions"
import { Link } from "gatsby"
import "./portfolio.styles.scss"
import Expensify from "../components/Images/Expensify"
import Omnifood from "../components/Images/Omnifood"
import { Helmet } from "react-helmet"

const Portfolio = ({ data }) => {
  return (
    <PageTransition
      defaultStyle={{
        transition: "left 700ms cubic-bezier(0.47, 0, 0.75, 0.72)",
        left: "100%",
        position: "absolute",
        width: "100%",
      }}
      transitionStyles={{
        entering: { left: "0%" },
        entered: { left: "0%" },
        exiting: { left: "100%" },
      }}
      transitionTime={500}
    >
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>William Cheshier</title>
          <link rel="canonical" href="https://williamcheshier.com/portfolio" />
        </Helmet>
        <h1 className="portfolio-header">Portfolio</h1>
        <div className="row">
          <Link className="categories" to="/applications">
            <h2>Applications</h2>
          </Link>
          <div className="mobile-image">
            <Link to="/applications">
              <Expensify />
            </Link>
          </div>
          <Link className="categories" to="/websites">
            <h2>Websites</h2>
          </Link>
          <div className="mobile-image" style={{ marginBottom: "10rem" }}>
            <Link to="/websites">
              <Omnifood />
            </Link>
          </div>
        </div>
        <div className="grid">
          <Link to="/applications">
            <Expensify />
          </Link>
          <Link to="/websites">
            <Omnifood />
          </Link>
        </div>
      </Layout>
    </PageTransition>
  )
}
export default Portfolio
// <div className="grid">
// {data.allFile.edges.map(image => (
//   <Img key={image.id} fluid={image.node.childImageSharp.fluid} />
// ))}
// </div>
